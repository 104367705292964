<style scoped>
.modal-card {
    width: auto;
}
.modal-card-body {
    width: auto;
}
</style>


<template>
  <div :class="{ 'modal-card': hasModalCard }">
    <header
      v-if="canUpdateDocument"
      class="modal-card-head"
    >
      <p class="modal-card-title">
        Edit node
      </p>
      <span
        class="is-pulled-right buttons"
      >
        <b-button
          v-if="user.role === 'admin'"
          type="is-danger"
          icon-left="delete"
          @click="remove"
        >
          Delete node
        </b-button>
        <b-button
          icon-left="arrow-up"
          @click="goToFailure"
        >Go to Failure</b-button>
        <template
          v-if="!creating"
        >
          <b-button
            @click="() => createRepairList()"
          >Generate RepairList</b-button>
          <b-button
            @click="() => createRepairList(true)"
          >Generate empty RL</b-button>
        </template>
      </span>
    </header>

    <section class="modal-card-body">
      <div class="columns">
        <div
          v-if="canUpdateDocument"
          class="column"
        >
          <a-select
            :manual-doc="manualDoc"
            field="part"
            options="parts"
            label="Select part"
            :to-pick="['id', 'name', 'supplier.id', 'supplier.name']"
          />

          <div class="columns">
            <div class="column">
              <a-input
                :manual-doc="manualDoc"
                field="number"
                label="Type a serial number"
                placeholder="Please fill in current serial number"
              />
            </div>
            <div class="column">
              <a-input
                :manual-doc="manualDoc"
                field="replacement.number"
                label="REPLACEMENT serial number"
                placeholder="Fill if the node has been replaced"
              />
            </div>
          </div>

          <a-select
            :manual-doc="manualDoc"
            field="state"
            :options="stateOptions"
          />

          <a-input
            :manual-doc="manualDoc"
            field="name"
            label="Failure description"
            type="textarea"
          />

          <b-field>
            <a-input
              :manual-doc="manualDoc"
              field="service.inspection"
              label="Inspection hours"
              type="number"
            />

            <a-input
              :manual-doc="manualDoc"
              field="service.repair"
              label="Repair hours"
              type="number"
            />

            <a-input
              :manual-doc="manualDoc"
              field="service.testing"
              label="Testing hours"
              type="number"
            />
          </b-field>

          <a-input
            :manual-doc="manualDoc"
            field="notes"
            label="Notes"
            type="textarea"
          />

          <a-input
            :manual-doc="manualDoc"
            field="visual_inspection"
            label="Visual inspection"
            type="textarea"
          />
        </div>
        <div
          v-else
          class="column"
        >
          <span v-html="doc.part ? `<i>Part type:</i> ${doc.part.name}<br/>` : ''" />
          <span v-html="doc.part && doc.part.supplier ? `<i>Part supplier:</i> ${doc.part.supplier.name}<br/>` : ''" />
          <span v-html="doc.number ? `<i>Serial number:</i> ${doc.number}<br/>` : ''" />
          <span v-html="doc.replacement && doc.replacement.number ? `<i>Replacement number:</i> ${doc.replacement.number}<br/>` : ''" />
          <br>
          <span
            :class="rowClass"
            v-html="doc.state ? `<i>State:</i> ${doc.state}<br/>` : ''"
          /><br>
          <span v-html="doc.name ? `<i>Description:</i> ${doc.name}<br/>` : ''" />
          <span v-html="doc.service && doc.service.inspection ? `<i>Inspection:</i> ${doc.service.inspection} hours<br/>` : ''" />
          <span v-html="doc.service && doc.service.repair ? `<i>Repair:</i> ${doc.service.repair} hours<br/>` : ''" />
          <span v-html="doc.service && doc.service.testing ? `<i>Testing:</i> ${doc.service.testing} hours<br/>` : ''" /><br>
          <span v-html="doc.notes ? `<i>Notes:</i> ${doc.notes}<br/>` : ''" />
          <span v-html="doc.visual_inspection ? `<i>Visual inspection:</i> ${doc.visual_inspection}<br/>` : ''" />
        </div>
        <div class="column is-one-quarter">
          <files
            :key="filesKey"
            :files-path="filesPath"
            :can-update="canUpdateDocument"
          />

          <parent
            :manual-doc="manualDoc"
          />

          <failure-state
            :object-doc="doc.failure"
            :editable="false"
          />
          <failure-vehicle
            :object-doc="doc.failure"
            :editable="false"
          />
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <button
        class="button is-default"
        @click="close"
      >
        Close
      </button>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { random, includes, concat } from 'lodash'
import Files from '@/components/Files'
import Parent from './FailureNode/Parent'
import FailureState from './Failure/State'
import FailureVehicle from './Failure/Vehicle'


export default {
  components: {
    Files,
    Parent,
    FailureState,
    FailureVehicle
  },
  props: {
    hasModalCard: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      creating: false,
      filesKey: random(1,99999)
    }
  },
  computed: {
    ...mapGetters({
      allFailureParts: 'failureParts/currentFailureItems',
      allFailureNodes: 'failureNodes/currentFailureItems'
    }),
    stateOptions() {
      return _.pull(_.clone(this.$store.getters["failureParts/fields"].state.options), 'received')
    },
    parts() {
      return _.filter(this.allFailureParts, item => item.parent && item.parent.id === this.doc.id)
    },
    nodes() {
      return _.filter(this.allFailureNodes, item => item.parent && item.parent.id === this.doc.id)
    },
    fileNames() {
      return _.map(this.files, 'name')
    },
    filesPath() {
      return `failureNodes/${this.doc.id}`
    },
    canUpdateDocument () {
      return this.$store.getters[`failureNodes/canUpdateDocument`](this.doc)
    },
    rowClass () {
      return this.$store.getters[`${this.module}/rowClass`](this.doc)
    }
  },
  asyncComputed: {
    async files () {
      const storageRef = await firebase.storage().ref()
      const pathRef = await storageRef.child(`failureNodes/${this.doc.id}`)
      const files = await pathRef.listAll()
      return files.items
    }
  },
  async created() {
    /*console.log('node', this.doc)
    console.log('ALLparts', this.allFailureParts)
    console.log('parts', this.parts)
    console.log('failure', this.$store.getters["currentDoc/currentDoc"])
    window.nodeForm = this*/
    await this.$store.dispatch('currentDoc/loadDoc')
  },
  methods: {
    goToFailure() {
      if(this.$store.state.route.name === "failure") {
        this.close()
      } else {
        this.$router.push({ name: 'failure', params: { id: this.doc.failure.id } })
      }
    },
    includes,
    async createRepairList (empty = false) {
      this.creating = true

      if(!empty) {
        this.$store.dispatch('failureNodes/set', {
          id: this.doc.id,
          generated_by: _.pick(this.user, ['id', 'name']),
          generated_at: new Date()
        })
      }

      const response = await fetch('https://us-central1-rams-e6d4f.cloudfunctions.net/createPdf', {
        method: 'POST',
        body: JSON.stringify({
          filename: `failureNodes/${this.doc.id}/${empty ? 'emptyR' : 'r'}epairList.pdf`,
          data: {
            node: this.doc,
            nodes: [],//this.nodes,
            parts: concat(this.nodes, this.parts),
            failure: this.$store.getters["currentDoc/currentDoc"],
            settings: {
              empty
            }
          },
          template: 'RepairList.vue'
        })
      })
      this.creating = false
      this.key = random()
      this.filesKey = random(1, 99999)
    },
    close () {
      if(this.hasModalCard) {
        this.$emit('close', this.doc)
      } else {
        this.$router.go(-1)
      }
    },
    remove () {
      this.$store.dispatch('failureNodes/delete', this.doc.id)
      this.$emit('close', null)
    }
  }
}
</script>
